import domReady from '@wordpress/dom-ready';

domReady(() => {
  const wpButtons = document.querySelectorAll(
    '.wp-block-button > .wp-element-button, .wp-block-file > .wp-element-button'
  );

  if (0 < wpButtons.length) {
    wpButtons.forEach((wpButton) => {
      const btnText = wpButton.textContent;
      const label = document.createElement('span');
      const icon = document.createElement('span');
      label.classList.add('btn-label');
      icon.classList.add('btn-icon');
      icon.classList.add('icon-fleche_droite');
      label.textContent = btnText;
      wpButton.textContent = '';
      wpButton.appendChild(label);
      wpButton.appendChild(icon);
    });
  }
});
