import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.news-carousel__carousel');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      new Swiper(carousel, {
        slidesPerView: 1,
        spaceBetween: 0,
        watchOverflow: true,
        navigation: {
          prevEl: carousel.querySelector('.swiper-button-prev'),
          nextEl: carousel.querySelector('.swiper-button-next'),
        },
        breakpoints: {
          600: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1080: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        },
      });
    });
  }
});
